<template>
  <div class="mx-2 py-2 sm:px-6">
    <base-card class="space-y-4">
      <div class="border-b border-gray-200 pb-5">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Pengiriman Retur Barang ke Pusat
          </h3>
        </div>
      </div>

      <div class="flex flex-col space-y-4">
        <retur-shipment-table-filter
          v-model="filter"
          @search="onSearch"
          @filter="onFilter"
        />
        <retur-shipment-table @page-change="onPageChange" @detail="onDetail" />
      </div>
    </base-card>

    <retur-shipment-detail-modal
      :visible="detailModal.visible"
      @close="detailModal.visible = false"
      @shipped="onShipped"
    />

    <loading v-if="isLoading" />
  </div>
</template>

<script>
import ReturShipmentDetailModal from '@/components/retur-shipment/retur-shipment-detail-modal.vue';
import ReturShipmentTable from '@/components/retur-shipment/retur-shipment-table.vue';
import ReturShipmentTableFilter from '@/components/retur-shipment/retur-shipment-table-filter.vue';
import { readReturShipment } from '@/mixins/retur-shipment/read-retur-shipment.mixin';

export default {
  mixins: [readReturShipment],
  components: {
    ReturShipmentDetailModal,
    ReturShipmentTable,
    ReturShipmentTableFilter,
  },
  data() {
    return {
      originOfficeCategoryName: 'Kantor Pelayanan',
    };
  },
};
</script>
